import React, { startTransition } from 'react'

const Navbar2 = () => {
  return (
    <>
    <div className="nabar2  bg-gray-700 flex justify-around list-none  text-sm text-slate-200 mobile:py-5
    mobile:flex-col mobile:justify-center mobile:items-center mobile:space-y-4 ">
        <li className='small:text-xs'>9696132246</li>
        <li className='small:text-xs'>alokpal258@gmail</li>
        <li className='small:text-xs'>3-5 BUSINESS DAYS DELIVERY & FREE RETURNS</li>
    </div>
    </>
  )
}

export default Navbar2 ;