export const category=[
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
]
export const datarecent=[
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
    {
        image:"http://safira.mallthemes.com/wp-content/uploads/2020/04/blog-1-700x445.jpg",
        name:"Creamy Shirmp Pasta With Corn And Tamato",
        date:"April 25, 2000",
        title:"Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus.",

    },
]