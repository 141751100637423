import React from 'react'
import Blog from '../Cmponent3/Blog/Blog'
import Category from '../Cmponent3/Category/Category'
import Footer from '../Component/Footer/Footer'
import Navbar from '../Component/Nvabar/Navbar'
const Pges3 = () => {
  return (
    <>
    <Navbar/>
    <Blog/>
    <Category/>
    <Footer/>
    </>
  )
}

export default Pges3