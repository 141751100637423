import React from 'react'
import New from '../component8/New'
const Newpage = () => {
  return (
    <div>
      <New/>
    </div>
  )
}

export default Newpage
