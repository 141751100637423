export const shippingdata = [
    {
        id:1,
        image: 'https://img.icons8.com/dotty/344/van.png',
        title: "Free Shipping",
        value: "ON ORDER OVER $100"
    },
    {   
        id:2,
        image: "https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/344/external-award-online-learning-vitaliy-gorbachev-lineal-vitaly-gorbachev-1.png",
        title: "Free Shipping",
        value: "ON ORDER OVER $100"
    },
    {
        id:3,
        image: 'https://img.icons8.com/pastel-glyph/344/online-support.png',
        title: "Free Shipping",
        value: "ON ORDER OVER $100"
    },
    {
        id:4,
        image: "https://img.icons8.com/external-wanicon-lineal-wanicon/344/external-vegetables-healthy-food-wanicon-lineal-wanicon.png",
        title: "Free Shipping",
        value: "ON ORDER OVER $100"
    },
]