export const productdata=[
    {
        id:1,
        image:"https://images.unsplash.com/photo-1660554042520-db71c7fea8d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title:"Apple China Imported",
        name:"Green Vegetables",
        price:100.00,
    },
    {
        index:2,
        image:"https://images.unsplash.com/photo-1518977822534-7049a61ee0c2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title:"Apple Gourd - 1kg",
        name:"Cruciferous",
        price:70.00,
    },
    {
        id:3,
        image:"https://images.unsplash.com/photo-1609196141706-91f4f03e1543?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1112&q=80",
        title:"Apple Golden Local",
        name:"Green Vegetables",
        price:80.00,
    },
    {
        id:3,
        image:"https://images.unsplash.com/photo-1519897831810-a9a01aceccd1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1331&q=80",
        title:"Bathua Saag/Leaves",
        name:"Allium",
        price:115.00,
    },
    {
        id:3,
        image:"https://images.unsplash.com/photo-1657299156271-d5a435dd65b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title:"Apple China Imported",
        name:"Green Vegetables",
        price:100.00,
    },
    {
        id:3,
        image:"https://images.unsplash.com/photo-1657299156271-d5a435dd65b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title:"Bathua Saag/Leaves",
        name:"Allium",
        price:115.00,
    },
    {
        id:3,
        image:"https://images.unsplash.com/photo-1657299156271-d5a435dd65b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title:"hii",
        name:"hahhaha",
        price:100,
    },
    {
        id:3,
        image:"https://images.unsplash.com/photo-1657299156271-d5a435dd65b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title:"Apple Golden Local",
        name:"Green Vegetables",
        price:80.00,
    },
    {
        id:3,
        image:"https://images.unsplash.com/photo-1657299156271-d5a435dd65b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title:"Apple Gourd - 1kg",
        name:"Cruciferous",
        price:70.00,
    },
    {
        id:3,
        image:"https://images.unsplash.com/photo-1657299156271-d5a435dd65b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title:"Apple China Imported",
        name:"Green Vegetables",
        price:100.00,
    },
]