export const blogdata=[
    {
        id:1,
        image:"https://demothemesky-be87.kxcdn.com/upstore-organic/wp-content/uploads/2018/04/blog-1-900x600.jpg",
        name:"TRANSLATION AND WTE COMPLITATION",
        writter:"John Carter",
        date:"November 27, 2007",
        para:"Solve challenges Action Against Hunger citizenry Martin Luther King Jr. Combat malaria, mobilize lasting change billionaire philanthropy revitalize"
    },
    {
        id:2,
        image:"https://demothemesky-be87.kxcdn.com/upstore-organic/wp-content/uploads/2018/04/blog-3-900x600.jpg",
        name:"TRANSLATION AND WTE COMPLITATION",
        writter:"John Carter",
        date:"November 27, 2007",
        para:"Solve challenges Action Against Hunger citizenry Martin Luther King Jr. Combat malaria, mobilize lasting change billionaire philanthropy revitalize"
    },
    {
        id:3,
        image:"https://demothemesky-be87.kxcdn.com/upstore-organic/wp-content/uploads/2018/04/blog-2-900x600.jpg",
        name:"TRANSLATION AND WTE COMPLITATION",
        writter:"John Carter",
        date:"November 27, 2007",
        para:"Solve challenges Action Against Hunger citizenry Martin Luther King Jr. Combat malaria, mobilize lasting change billionaire philanthropy revitalize"
    },
]