import React from 'react'
import Register from '../Component7/Register';
import Navbar from '../Component/Nvabar/Navbar';
import Header from '../Component7/Header';
import Footer from '../Component/Footer/Footer';
const Pages7 = () => {
  return (
    <>
    <Navbar/>
    <Header/>
    <Register/>
    <Footer/>
    </>
  )
}

export default Pages7