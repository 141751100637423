import React from 'react'
import Blog from '../About/Blog/Blog'
import About from '../About/Aboutfile/About'
import Navbar from '../Component/Nvabar/Navbar';
import Footer from '../Component/Footer/Footer'
const Pages5 = () => {
  return (
    <>
    <Navbar/>
    <Blog/>
    <About/>
    <Footer/>
    </>
  )
}

export default Pages5