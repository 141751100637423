import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './Pages/App';
import Page2 from './Pages/Page2';
ReactDOM.render(
<>    <App /></>,

  document.getElementById('root'),
);
