export const relateddata = [
    {
        image: " https://images.unsplash.com/photo-1615484477545-04af2a4d851c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
        name: "Grape Fruit Local",
        title: "Allium",
        price: "$110.00"
    },
    {
        image: " https://images.unsplash.com/photo-1615484477545-04af2a4d851c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
        name: "Grape Fruit Local",
        title: "Allium",
        price: "$110.00"
    },
    {
        image: " https://images.unsplash.com/photo-1615484477545-04af2a4d851c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
        name: "Grape Fruit Local",
        title: "Allium",
        price: "$110.00"
    },
    {
        image: " https://images.unsplash.com/photo-1615484477545-04af2a4d851c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
        name: "Grape Fruit Local",
        title: "Allium",
        price: "$110.00"
    },
]