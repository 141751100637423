import React from 'react'
import Login from '../Component6/Login';
import Navbar from '../Component/Nvabar/Navbar'
import Header from '../Component6/Header';
import Footer from '../Component/Footer/Footer';
const Pages6 = () => {
  return (
    <>

    <Navbar/>
    <Header/>
    <Login/>
    <Footer/>
    </>
  )
}

export default Pages6